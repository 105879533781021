<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                v-model.trim="nombre"
                label="Nombre"
                dense
                hide-details="auto"
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombre),
                    rules.maxLength(nombre, 100)
                  ])
                "
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="4" sm="6" md="4" class="py-0">
              <v-subheader class="py-0 px-0">
                <v-switch
                  class="ml-1"
                  v-model="toLiq"
                  dense
                  outlined
                ></v-switch>
                toLiq?
              </v-subheader>
            </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadigSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "EditTipoOperacionProveedores",
  props: {
    tipoOperacion: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: "Editar tipo de operación",
    rules: rules,
    nombre: null,
    // toLiq: false,
    loadigSaveBtn: false
  }),
  created() {
    if (this.tipoOperacion != null) this.setTipoOperacion();
    else this.formEditTitle = "Nuevo tipo de operación";
  },
  methods: {
    ...mapActions({
      postTipoOperacion: "proveedores/postTipoOperacion",
      setAlert: "user/setAlert"
    }),
    async setTipoOperacion() {
      this.nombre = this.tipoOperacion.toNom;
      //   this.toLiq = this.tipoOperacion.toLiq;
    },
    async saveEdit() {
      this.loadigSaveBtn = true;
      const data = {
        toId: this.tipoOperacion != null ? this.tipoOperacion.toId : null,
        toNom: this.nombre
        // toLiq: this.toLiq
      };
      try {
        const res = await this.postTipoOperacion(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.$emit("closeAndReload", true);
        }
      } catch {}
      this.loadigSaveBtn = false;
    },
    closeModal() {
      this.$emit("closeAndReload", false);
    }
  }
};
</script>

<style></style>
